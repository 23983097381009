import React from 'react'
import clsx from 'clsx'

import * as styles from './salesBanner.module.scss'
import salesTag from '../../assets/sales/advertising.png'
import { Button } from '../button'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useMediaQuery } from '../../utils/hooks'
import { FiArrowRight } from 'react-icons/fi'

type SalesBannerProps = {
  hasBackground?: boolean
}

export const SalesBanner: React.FC<SalesBannerProps> = ({ hasBackground }) => {
  const isMobile = useMediaQuery(`768`)

  return (
    <>
      <div
        className={clsx(styles.salesBanner, { [styles.hasBg]: hasBackground })}
      >
        <div className="container" style={{ height: `100%` }}>
          <div className={styles.sales}>
            <div className={styles.icon}>
              <img src={salesTag} title="salesTag" />
            </div>
            <div className={styles.title}>
              <h1>50% OFF</h1>
              <span>
                Válidos nos planos <strong>Profissional</strong> e{' '}
                <strong>Empresarial!</strong>
                {' 😃 '}
                Promoção ativa até dia <strong>25 de Outubro!</strong>
                {isMobile && (
                  <AnchorLink to={`/contactSales`}>
                    Clique e saiba mais <FiArrowRight />
                  </AnchorLink>
                )}
              </span>
            </div>
            {!isMobile && (
              <div className={styles.cta}>
                <AnchorLink to={`/contactSales`}>
                  <Button color="primary">Clique e saiba mais</Button>
                </AnchorLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

SalesBanner.defaultProps = {
  hasBackground: false,
}
