import React, { useEffect } from 'react'
import { motion, useAnimation, AnimationControls } from 'framer-motion'

export const RobertyIcon: React.FC = ({ ...props }) => {
  const controlHead = useAnimation()
  const controlEar = useAnimation()
  const controlHelmet = useAnimation()
  const controlFace = useAnimation()
  const controlRightEye = useAnimation()
  const controlLeftEye = useAnimation()
  const controlAll = useAnimation()

  useEffect(() => {
    showAnimation(controlAll)
    animationEyesOpen(controlRightEye)
    animationEyesOpen(controlLeftEye)
  }, [])

  const showAnimation = async (animation: AnimationControls) => {
    await animation.start({
      scale: [1, 1.01, 1.02, 1.03, 1.04, 1.03, 1.02, 1.01, 1],
      transition: {
        ease: `linear`,
        duration: 5,
        repeat: 6,
      },
    })
  }

  const animationEyesOpen = async (animation: AnimationControls) => {
    const eyesAnimation = [
      0, 0, 0, 0.1, 0, 0, 0, 0, 0, 0.4, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0.1, 0.2,
      0.3, 0.5, 0.7, 1,
    ]
    // End
    await animation.start({
      fillOpacity: eyesAnimation,
      transition: {
        ease: `linear`,
        duration: 3,
        delay: 1,
      },
    })
  }

  return (
    <motion.svg
      version="1.1"
      viewBox="0 0 300 173"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      animate={controlAll}
    >
      {/* HEADER */}
      <motion.path
        d="m38.868 24.125 19.558 0.28096c0.01648 0.09614-0.36489 0.42094-0.10932 0.45429 50.54-1.0163 50.56-1.1895 200.79 4.6273 0 0 29.307 2.6189 38.66 39.658 9.3532 37.039-17.21 59.611-17.21 59.611s-7.4202 6.4849-23.009 9.1038c-15.589 2.6189-204.52 34.919-204.52 34.919s-8.8586 1.3512-19.094-4.1434c-7.5779-4.0679-15.442-12.57-22.683-24.852-14.217-32.362-10.912-60.422-10.912-60.422s3.2424-40.156 24.817-53.376c5.8257-4.2338 13.718-5.8613 13.718-5.8613z"
        fill="#cfd8dc"
        animate={controlHead}
      />

      {/* EAR */}
      <motion.ellipse
        transform="matrix(.97854 -.20607 .12656 .99196 0 0)"
        cx="25.499"
        cy="105.2"
        rx="37.09"
        ry="60.257"
        fill="#eceff1"
        animate={controlEar}
      />

      {/* HELMET */}
      <motion.path
        d="m58.215 24.85s15.785-28.219 48.942-24.515c34.568 1.7637 116.67 6.9224 116.67 6.9224 11.279 1.4011 15.044 1.6198 26.428 8.2221 4.9529 2.8435 8.9 6.3106 10.735 8.3895 1.2004 1.3602-0.68673 4.0202-0.68673 4.0202s-5.4759 8.6981-8.1255 12.745l-131.35-0.26455s-6.8367-9.4458-17.955-13.153c-9.6396-3.2141-24.113-1.3795-44.654-2.367z"
        fill="#eceff1"
        animate={controlHelmet}
      />

      {/* FACE */}
      <motion.path
        d="m102.1 48.443 168.48-1.3718s20.452 0.62355 23.695 35.293c-0.12471 34.545-21.076 37.912-21.076 37.912l-159.13 22.947s-11.711 2.9719-14.548-6.7006c-0.28956-4.0879-0.56827-9.2296-0.66684-17.493-1.1224-33.422-3.2424-61.108-3.2424-61.108s-0.37413-7.3579 6.4849-9.4779z"
        fill="#4778f7"
        animate={controlFace}
      />

      {/* RIGHT EYES */}
      <motion.rect
        transform="matrix(.9981 -.061688 .049035 .9988 0 0)"
        x="153.69"
        y="70.009"
        width="15.598"
        height="64.584"
        ry="7.7991"
        fill="#03ffff"
        animate={controlRightEye}
      />

      {/* LEFT EYES */}
      <motion.rect
        transform="matrix(.99807 -.062086 .04872 .99881 0 0)"
        x="241.34"
        y="72.94"
        width="13.436"
        height="55.989"
        ry="6.7612"
        fill="#03ffff"
        animate={controlLeftEye}
      />
    </motion.svg>
  )
}
