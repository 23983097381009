// extracted by mini-css-extract-plugin
export var blueGradient = "salesBanner-module--blue-gradient--MzgBq";
export var blueGradientCitric = "salesBanner-module--blue-gradient-citric--iuyMt";
export var cta = "salesBanner-module--cta--vpg1y";
export var darkBlueGradient = "salesBanner-module--dark-blue-gradient---ojib";
export var darkGradient = "salesBanner-module--dark-gradient--Jj0Lz";
export var hasBg = "salesBanner-module--hasBg--GkPUD";
export var icon = "salesBanner-module--icon--j183m";
export var lightGradient = "salesBanner-module--light-gradient--UDwGT";
export var purpleBlueGradient = "salesBanner-module--purple-blue-gradient--M+Gy7";
export var sales = "salesBanner-module--sales--o7UlY";
export var salesBanner = "salesBanner-module--salesBanner--Eyfe0";
export var softBlueGradient = "salesBanner-module--soft-blue-gradient--htTxq";
export var softGreyGradient = "salesBanner-module--soft-grey-gradient--QInDy";
export var title = "salesBanner-module--title--qe3Ev";