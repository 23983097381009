import { useState, useEffect } from 'react'

export const useMediaQuery = (width: string) => {
  const [targetReached, setTargetReached] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)

    if (media.matches) {
      setTargetReached(true)
    }

    const listener = () => {
      setTargetReached(media.matches)
    }

    media.addListener(listener)

    return () => media.removeListener(listener)
  }, [targetReached, width])

  return targetReached
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
