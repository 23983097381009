import moment from 'moment'
import queryString from 'query-string'
import Cookies from 'universal-cookie'

const isBrowser = () => typeof window !== "undefined"

let cookieDomain = `.roberty.app`
if (process.env.NODE_ENV === `development`) cookieDomain = `localhost`

const cookieOptions = {
  domain: cookieDomain,
  path: `/`,
  expires: moment().add(90, `years`).toDate()
}

export enum CurrenciesIsoCodes {
  BRL = `BRL`,
  USD = `USD`,
}

type CurrencyType = {
  isoCode: keyof typeof CurrenciesIsoCodes
  name: { [key: string]: string }
  symbol: string
  locale: string
  flagIcon: string
}

export const Currencies: Record<CurrenciesIsoCodes, CurrencyType> = {
  BRL: {
    locale: `pt-BR`,
    isoCode: `BRL`,
    name: {
      'pt-BR': `Real brasileiro`,
      'en-US': `Brazilian real`,
    },
    symbol: `R$`,
    flagIcon: `brazil.svg`,
  },
  USD: {
    locale: `en-US`,
    isoCode: `USD`,
    name: {
      'pt-BR': `Dólar americano`,
      'en-US': `American dollar`,
    },
    symbol: `US$`,
    flagIcon: `unitedStates.svg`,
  },
}

const availableLanguages = [`pt-BR`, `en-US`]
const defaultLanguage = `en-US`

export const LocaleService = new class {

  private cookie = new Cookies()

  start() {
    this.apply()
  }

  setDefaultCurrency() {
    if (this.cookie.get(`currency`)) return
    switch (this.get()) {
      case `en-US`: {
        this.cookie.set(`currency`, CurrenciesIsoCodes.USD, cookieOptions)
        break
      }
      default:
      case `pt-BR`: {
        this.cookie.set(`currency`, CurrenciesIsoCodes.BRL, cookieOptions)
        break
      }
    }
  }

  setCurrency(_currency: CurrenciesIsoCodes | undefined | null) {
    const currency = Object.values(Currencies).find(c => c.isoCode === _currency)
    if (currency) {
      this.cookie.set(`currency`, currency.isoCode, cookieOptions)
      this.cookie.set(`locale`, currency.locale, cookieOptions)
    }
  }

  getCurrency() {
    this.setDefaultCurrency()
    return this.cookie.get(`currency`)
  }

  updateFromLocationSearch() {
    const location = typeof window !== 'undefined' && window.location
    if (!location) return
    const { lang } = queryString.parse(location.search)
    if (lang) this.set(lang as string)
  }

  apply(locale?: string) {
    if (!locale) locale = this.get()
    document.documentElement.setAttribute(`lang`, locale)
  }

  get() {
    this.updateFromLocationSearch()
    let locale = this.cookie.get(`locale`) as string
    if (!locale) locale = Intl.DateTimeFormat().resolvedOptions().locale
    if (!this.isLanguageAvailable(locale)) locale = defaultLanguage
    return locale
  }

  set(locale: string) {
    if (!this.isLanguageAvailable(locale)) locale = defaultLanguage
    const currency = Object.values(Currencies).find(c => c.locale === locale)
    if (currency) this.cookie.set(`currency`, currency.isoCode, cookieOptions)
    this.cookie.set(`locale`, locale, cookieOptions)
    this.apply(locale)
  }

  private isLanguageAvailable(locale: string) {
    return availableLanguages.includes(locale)
  }

}()

export default LocaleService
