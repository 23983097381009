import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { useBoolean, useInput } from 'react-hanger'
import toast from 'react-hot-toast'
import { CgSpinner } from 'react-icons/cg'
import {
  FiAlertCircle,
  FiBriefcase,
  FiChevronLeft,
  FiHeadphones,
  FiMail,
  FiPhone,
  FiSend,
  FiUser,
  FiUsers,
  FiVideo,
} from 'react-icons/fi'
import InputMask from 'react-input-mask'

import i18n from '../../i18n'
import { api } from '../../utils/api'
import { Button } from '../button'
import { actionList, actionListType } from './actions'
import * as styles from './contactModal.module.scss'
import { ContactModalContext } from './context'

interface ContactActionsProps {
  isFormContact?: boolean
}

export const ContactActions: React.FC<ContactActionsProps> = ({
  isFormContact,
}) => {
  const [callItem, setCallItem] = useState<actionListType | undefined>(
    undefined
  )

  const name = useInput(``)
  const phone = useInput(``)
  const loading = useBoolean(false)

  useEffect(() => {
    if (ContactModalContext.formCallVisible) {
      setCallItem(actionList.find((item) => item.type === `call`))
    }
  }, [ContactModalContext.formCallVisible])

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      loading.setTrue()
      try {
        await api.post(`1/website/callbackToLead`, {
          name: name.value,
          phone: phone.value,
        })
        toast.success(`${i18n[`common.successContact`]}`)
        name.clear()
        phone.clear()
        ContactModalContext.formCallVisible = false
      } catch (error) {
        toast.error(
          `${i18n[`common.errorTryAgain`]}`
        )
        console.error(error)
      } finally {
        loading.setFalse()
        name.clear()
        phone.clear()
      }
    },
    [loading, name, phone, ContactModalContext]
  )

  return (
    <div className={clsx(styles.actions)}>
      {isFormContact ? (
        <>
          <div>
            {callItem && (
              <div
                className={clsx(styles.actionItem, styles.formType, {
                  [styles.call]: callItem.type === 'call',
                })}
              >
                <div className={styles.actionIcon}>
                  {callItem.icon}

                  <div
                    className={styles.goBack}
                    onClick={() => {
                      ContactModalContext.formCallVisible = false
                    }}
                  >
                    <FiChevronLeft size={20} /> {i18n[`common.back`]}
                  </div>
                </div>
                <div className={styles.actionContent}>
                  <div className="row d-flex">
                    <div className="col-12">
                      <h3>{callItem.title}</h3>
                      <p>{callItem.description}</p>
                    </div>

                    <div className={clsx(styles.form, 'col-12 my-4')}>
                      <form onSubmitCapture={handleSubmit}>
                        <div className="input-group mb-3">
                          <div className="prepend-icon">
                            <FiUser />
                          </div>
                          <input
                            name="name"
                            type="text"
                            placeholder={i18n[`common.fullName`]}
                            className="form-control small modalInput"
                            value={name.value}
                            onChange={name.onChange}
                            disabled={loading.value}
                          />
                        </div>

                        <div className="input-group">
                          <div className="prepend-icon">
                            <FiPhone />
                          </div>
                          <input
                            name="phone"
                            type="text"
                            placeholder={i18n[`common.phone`]}
                            className="form-control small modalInput"
                            value={phone.value}
                            onChange={phone.onChange}
                            disabled={loading.value}
                          />
                        </div>

                        <Button
                          color="primary"
                          className="mt-4"
                          size="small"
                          type="submit"
                          icon={
                            loading.value ? (
                              <CgSpinner className="icon-spin" size={18} />
                            ) : (
                              <FiSend size={18} />
                            )
                          }
                          disabled={loading.value}
                          iconPosition="right"
                        >
                          {loading.value
                            ? `${i18n[`common.sendingContact`]}`
                            : `${i18n[`common.sendContact`]}`}
                        </Button>
                      </form>
                    </div>

                    <div className="col-12 mb-3">
                      <hr />
                    </div>

                    <div className={clsx(styles.disclaimer, 'col-12')}>
                      {i18n[`common.sendInfo`]}
                      <a href="/privacyPolicy">
                        {' '}
                        {i18n[`common.privacyPolicy.`]}
                        {' '}
                      </a>
                      {i18n[`common.returnCalls`]}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {actionList.map((item: actionListType, index) => (
            <LinkCustom
              item={item}
              key={index}
              isFunction={item.actionLink ? false : true}
            >
              <div
                className={clsx(styles.actionItem, {
                  [styles.call]: item.type === 'call',
                  [styles.schedule]: item.type === 'schedule',
                  [styles.whats]: item.type === 'whats',
                })}
              >
                <div className={styles.actionIcon}>{item.icon}</div>
                <div className={styles.actionContent}>
                  <div className="row d-flex">
                    <div className="col-12">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                    <div className="col-12 mt-3">
                      <span>{item.actionText}</span>
                    </div>
                  </div>
                </div>
              </div>
            </LinkCustom>
          ))}
        </>
      )}
    </div>
  )
}

export const LinkCustom: React.FC<{
  item: actionListType
  isFunction: boolean
}> = ({ item, isFunction, ...props }) => {
  if (isFunction) {
    return (
      <a
        onClick={(event) => {
          // @ts-ignore
          item.actionFn(event)
        }}
      >
        {props.children}
      </a>
    )
  }

  return (
    <a href={item.actionLink} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  )
}

export const ContactSales: React.FC = () => {
  const loading = useBoolean(false)
  const name = useInput(``)
  const email = useInput(``)
  const phone = useInput(``)
  const businessName = useInput(``)
  const businessSize = useInput(``)
  const messageText = useInput(``)

  const features = [
    {
      icon: <FiHeadphones />,
      title: `${i18n[`common.support`]}`,
      description: `${i18n[`common.supportDescription`]}`,
    },
    {
      icon: <FiAlertCircle />,
      title: `${i18n[`common.monitoring24/7`]}`,
      description: `${i18n[`common.monitoringDescription`]}`,
    },
    {
      icon: <FiVideo />,
      title: `${i18n[`common.onlineTraining`]}`,
      description: `${i18n[`common.onlineTrainingDescription`]}`,
    },
  ]

  const sizeList = [
    { id: `1-19`, name: `1-19` },
    { id: `20-49`, name: `20-49` },
    { id: `50-99`, name: `50-99` },
    { id: `100-250`, name: `100-250` },
    { id: `251-1000`, name: `251-1000` },
    { id: `1000+`, name: `1000+` },
  ]

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      loading.setTrue()
      try {
        await api.post(`1/website/contactForm`, {
          name: name.value,
          email: email.value,
          phone: phone.value,
          businessName: businessName.value,
          businessSize: businessSize.value,
          message: messageText.value,
        })
        ContactModalContext.visible = false
        ContactModalContext.isContactSales = false
        toast.success(
          `${i18n[`common.successContact`]}`
        )
        name.clear()
        email.clear()
        phone.clear()
        businessName.clear()
        businessSize.clear()
        messageText.clear()
      } catch (error) {
        toast.error(
          `${i18n[`common.errorTryAgain`]}`
        )
        console.error(error)
      } finally {
        loading.setFalse()
      }
    },
    [loading, name, email, phone, businessName, messageText]
  )

  return (
    <div className={clsx(`container`, styles.mainContactSales)}>
      <div className={clsx('row', styles.form)}>
        <div className={clsx('col-12 col-lg-6', styles.sideContent)}>
          <div className={styles.title}>
            <h3>
              {i18n[`common.contact`]} <br /> {i18n[`common.salesTeam`]}
            </h3>
            <p>
              {i18n[`common.getAll`]}
            </p>
          </div>
          <div className={styles.features}>
            <ul>
              {features.map((item, key) => (
                <li key={key}>
                  <div className={styles.feature}>
                    {item.icon}
                    <div>
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className={styles.faq}>
            <p>
              {i18n[`common.generalQuestions`]}{` `}
              <a
                href="https://docs.roberty.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n[`common.FAQ`]}
              </a>
            </p>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className={styles.formContent}>
            <form onSubmitCapture={handleSubmit}>
              <div className="input-group mb-3">
                <div className="prepend-icon">
                  <FiUser />
                </div>
                <input
                  required={true}
                  name="name"
                  type="text"
                  placeholder={i18n[`common.fullName`]}
                  className="form-control  modalInput"
                  value={name.value}
                  onChange={name.onChange}
                  disabled={loading.value}
                />
              </div>

              <div className="input-group mb-3">
                <div className="prepend-icon">
                  <FiMail />
                </div>
                <input
                  required={true}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  className="form-control  modalInput"
                  value={email.value}
                  onChange={email.onChange}
                  disabled={loading.value}
                />
              </div>

              <div className="input-group mb-3">
                <div className="prepend-icon">
                  <FiPhone />
                </div>
                <InputMask
                  mask="(99) 99999-9999"
                  required={true}
                  name="phone"
                  type="text"
                  placeholder={i18n[`common.phone`]}
                  className="form-control modalInput"
                  value={phone.value}
                  onChange={phone.onChange}
                  disabled={loading.value}
                />
              </div>

              <div className="input-group mb-3">
                <div className="prepend-icon">
                  <FiBriefcase />
                </div>
                <input
                  required={true}
                  name="businessName"
                  type="text"
                  placeholder={i18n[`common.businessName`]}
                  className="form-control modalInput"
                  value={businessName.value}
                  onChange={businessName.onChange}
                  disabled={loading.value}
                />
              </div>

              <div className="input-group mb-3">
                <div className="prepend-icon">
                  <FiUsers />
                </div>
                <select
                  name="businessSize"
                  className="form-control modalInput"
                  onChange={businessSize.onChange}
                  disabled={loading.value}
                  value={businessSize.value}
                  required={true}
                >
                  <option disabled value={``}>
                    {i18n[`common.businessSize`]}
                  </option>
                  {sizeList.map((item, key) => (
                    <option key={key} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-group ">
                <textarea
                  required={true}
                  placeholder={i18n[`common.messageText`]}
                  name="messageText"
                  className="form-control modalInput"
                  value={messageText.value}
                  onChange={messageText.onChange}
                  disabled={loading.value}
                  rows={3}
                ></textarea>
              </div>
              <div className={styles.buttonAction}>
                <p className={styles.privacy}>
                  {i18n[`common.sendInfo`]}
                  {` `}
                  <a href="/privacyPolicy">
                    {i18n[`common.privacyPolicy`]}
                  </a>
                  .
                </p>

                <Button
                  color="primary"
                  className="mt-4"
                  type="submit"
                  icon={
                    loading.value ? (
                      <CgSpinner className="icon-spin" size={18} />
                    ) : (
                      <FiSend size={18} />
                    )
                  }
                  disabled={loading.value}
                  iconPosition="right"
                >
                  {loading.value ? `${i18n[`common.sendingContact`]}` : `${i18n[`common.sendContact`]}`}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
