import clsx from 'clsx'
import React from 'react'

import * as styles from './button.module.scss'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

type ButtonType = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

interface ButtonProps extends ButtonType {
  iconPosition?: `right` | `left`
  icon?: React.ReactNode
  color: `primary` | `darkBlue` | `deepDarkBlue` | `rose`
  size?: `large` | `small`
  id?: string
  href?: string
  linkTitle?: string
  isOutline?: boolean
}

const BaseButtonComponent: React.FC<ButtonProps> = ({
  iconPosition,
  icon,
  color,
  size,
  id,
  isOutline,
  ...props
}) => {
  return (
    <button
      id={id}
      className={clsx(props.className, {
        [styles.button]: true,
        [styles.primary]: color === 'primary',
        [styles.darkBlue]: color === 'darkBlue',
        [styles.deepDarkBlue]: color === 'deepDarkBlue',
        [styles.rose]: color === 'rose',
        [styles.sizeLarge]: size === 'large',
        [styles.sizeSmall]: size === 'small',
        [styles.outline]: isOutline,
      })}
      disabled={props.disabled}
      type={props.type}
      onClick={props.onClick}
    >
      {iconPosition === 'left' && icon}
      <span>{props.children}</span>
      {iconPosition === 'right' && icon}
    </button>
  )
}

export const Button: React.FC<ButtonProps> = ({
  iconPosition,
  icon,
  color,
  size,
  id,
  href,
  linkTitle,
  ...props
}) => {
  if (href) {
    return (
      <AnchorLink to={href} title={linkTitle}>
        <BaseButtonComponent
          iconPosition={iconPosition}
          icon={icon}
          color={color}
          size={size}
          id={id}
          {...props}
        />
      </AnchorLink>
    )
  }

  return (
    <BaseButtonComponent
      iconPosition={iconPosition}
      icon={icon}
      color={color}
      size={size}
      id={id}
      {...props}
    />
  )
}
