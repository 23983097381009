import clsx from 'clsx'
import React, { useState } from 'react'
import { motion, useCycle } from 'framer-motion'

import * as styles from './header.module.scss'

interface MobileMenuProps {
  isSticky: boolean
}

const MotionPath = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
)

const menuMobile = {
  open: () => ({
    top: '80px',
    left: 0,
    transition: {
      type: 'tween',
      stiffness: 20,
    },
  }),
  closed: {
    top: '80px',
    left: '-1500px',
    transition: {
      delay: 0.2,
      type: 'tween',
      stiffness: 20,
    },
  },
}

const menuMobileBackdrop = {
  open: () => ({
    clipPath: `circle(2000px at 40px 40px)`,
    transition: {
      type: 'tween',
      stiffness: 20,
      delay: 0.3,
    },
  }),
  closed: {
    clipPath: 'circle(0 at 0px 0px)',
    transition: {
      delay: 0.2,
      type: 'tween',
      stiffness: 20,
    },
  },
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  isSticky,
  children,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isOpen, toggleOpen] = useCycle(false, true)

  return (
    <motion.nav
      className={clsx(styles.mobileNav, { [styles.sticky]: isSticky })}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
    >
      <button className={styles.toggle} onClick={() => toggleOpen()}>
        <svg width="16" height="16" viewBox="0 0 20 20">
          <MotionPath
            variants={{
              closed: { d: 'M 2 2.5 L 20 2.5' },
              open: { d: 'M 3 16.5 L 17 2.5' },
            }}
          />
          <MotionPath
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
          />
          <MotionPath
            variants={{
              closed: { d: 'M 2 16.346 L 20 16.346' },
              open: { d: 'M 3 2.5 L 17 16.346' },
            }}
          />
        </svg>
      </button>

      <motion.div
        className={clsx(styles.menuMobile, {
          [styles.active]: menuOpen,
        })}
        variants={menuMobile}
      >
        {children}
      </motion.div>
      <motion.div
        variants={menuMobileBackdrop}
        className={styles.backdrop}
      ></motion.div>
    </motion.nav>
  )
}
