import React, { ChangeEvent, SyntheticEvent } from 'react'

import { FiPhoneCall, FiCalendar } from 'react-icons/fi'
import { AiOutlineWhatsApp } from 'react-icons/ai'

import { ContactModalContext } from './context'
import i18n from '../../i18n'

export interface actionListType {
  title: string
  description: string
  actionText: string
  type: `call` | `schedule` | `whats`
  actionFn: (e: ChangeEvent<SyntheticEvent>) => void
  actionLink?: string
  icon: React.ReactNode
}

export const wppText = `${i18n[`common.wppText`]}`

export const actionList: actionListType[] = [
  {
    title: `${i18n[`common.callToYou`]}`,
    description: `${i18n[`common.backToyou`]}`,
    actionText: `${i18n[`common.wantCall`]}`,
    type: 'call',
    icon: <FiPhoneCall />,
    actionFn: () => {
      if (!ContactModalContext.visible) {
        ContactModalContext.visible = true
      }
      ContactModalContext.formCallVisible = true
    },
  },
  {
    title: `${i18n[`common.demonstrationTest`]}`,
    description: `${i18n[`common.chooseDate`]}`,
    actionText: `${i18n[`common.wantSchedule`]}`,
    type: 'schedule',
    icon: <FiCalendar />,
    actionFn: (e) => {
      e.preventDefault()
      ContactModalContext.visible = false
      // @ts-ignore
      Calendly.initPopupWidget({
        url: 'https://calendly.com/roberty-app/roberty',
        prefill: {
          customAnswers: {
            a3: 1,
          },
        },
      })
    },
  },
  {
    title: `WhatsApp`,
    description: `(16) 98200-0079`,
    actionText: `${i18n[`common.openWpp`]}`,
    type: 'whats',
    icon: <AiOutlineWhatsApp />,
    actionFn: (e) => {
      e.preventDefault()
      ContactModalContext.visible = false
      window.open(
        `https://wa.me/5516982000079?lang=pt-BR&text=${wppText}`,
        `_blank`,
        `noreferrer,noopener`
      )
    },
  },
]
