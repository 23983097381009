// extracted by mini-css-extract-plugin
export var blueGradient = "footer-module--blue-gradient--x0q5D";
export var blueGradientCitric = "footer-module--blue-gradient-citric--XfEiY";
export var copyright = "footer-module--copyright--Bg9Rz";
export var cyan = "footer-module--cyan--AyKQg";
export var darkBlueGradient = "footer-module--dark-blue-gradient--wvsrk";
export var darkGradient = "footer-module--dark-gradient--IN26k";
export var divider = "footer-module--divider--wMnGq";
export var footer = "footer-module--footer--lU7l1";
export var footerItems = "footer-module--footerItems--ZSSL3";
export var info = "footer-module--info--yNAsm";
export var infoCard = "footer-module--infoCard--NkHYU";
export var largeFooter = "footer-module--largeFooter--tCUOs";
export var lightGradient = "footer-module--light-gradient--H-ubi";
export var linkItem = "footer-module--linkItem--0WcFZ";
export var links = "footer-module--links--83gnD";
export var logo = "footer-module--logo--5nyW8";
export var purpleBlueGradient = "footer-module--purple-blue-gradient--NPA4R";
export var rose = "footer-module--rose--cDKTH";
export var socialList = "footer-module--socialList--Oi8Vr";
export var softBlueGradient = "footer-module--soft-blue-gradient--jlENC";
export var softGreyGradient = "footer-module--soft-grey-gradient--Y1+l2";