import React from 'react'
import { observer } from 'mobx-react-lite'

import * as styles from './loading.module.scss'

import { RobertyIcon } from './robertyIcon'
import { LoadingContext } from './context'
import clsx from 'clsx'
import i18n from '../../i18n'

export interface LoadingProps {
  context: typeof LoadingContext
}

export const Loading: React.FC<LoadingProps> = observer(({ context }) => {
  return (
    <div
      className={clsx(styles.loading, {
        [styles.active]: context.loading,
        [styles.exit]: context.exit,
      })}
    >
      <div className={styles.content}>
        <RobertyIcon />
        <p>{i18n[`common.loading`]}</p>
      </div>
    </div>
  )
})
