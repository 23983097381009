import React from 'react'

import { Toaster } from 'react-hot-toast'

export const ToastAlert: React.FC = () => {
  return (
    <Toaster
      position="top-center"
      toastOptions={{
        style: {
          zIndex: 9999,
          color: '#0b264b',
          padding: '22px',
          minWidth: '500px',
        },
        success: {
          duration: 5000,
          iconTheme: {
            primary: '#4778f7',
            secondary: '#fff',
          },
        },
        error: {
          iconTheme: {
            primary: '#ff5752',
            secondary: '#fff',
          },
        },
      }}
    />
  )
}
