import { wppText } from '../components/contactModal/actions'
import i18n from '../i18n'
export interface MenuItemsInterface {
  text: string
  sameOrigin: boolean
  href: string
  position?: string
  isFunction?: boolean
}

export interface FooterItemsInterface {
  item: string
  links: MenuItemsInterface[]
}

export const headerMenuItems: MenuItemsInterface[] = [
  // {
  //   text: `${i18n[`common.partner`]}`,
  //   sameOrigin: true,
  //   href: `/partners`,
  //   position: 'left',
  // },
  {
    text: `${i18n[`common.SalesTeam`]}`,
    sameOrigin: true,
    href: `/contactSales`,
    position: 'left',
  },
  {
    text: `Blog`,
    sameOrigin: false,
    href: `https://blog.roberty.app/`,
    position: 'left',
  },
  {
    text: `${i18n[`common.docs`]}`,
    sameOrigin: false,
    href: `https://docs.roberty.app/`,
    position: 'left',
  },
  {
    text: `${i18n[`common.logIn`]}`,
    sameOrigin: false,
    href: `https://studio.roberty.app/`,
    position: 'left',
  },
]

export const footerMenuLinks: FooterItemsInterface[] = [
  {
    item: `${i18n[`common.institutional`]}`,
    links: headerMenuItems,
  },
  {
    item: `${i18n[`common.supportPlan`]}`,
    links: [
      {
        text: `${i18n[`common.docs`]}`,
        sameOrigin: false,
        href: `https://docs.roberty.app/`,
      },
      {
        text: `${i18n[`common.schedule`]}`,
        sameOrigin: false,
        isFunction: true,
        href: `#`,
      },
      {
        text: `${i18n[`common.PrivacyPolicy`]}`,
        sameOrigin: true,
        href: `/privacyPolicy`,
      },
    ],
  },
  {
    item: `${i18n[`common.contacts`]}`,
    links: [
      {
        text: `(16) 98200-0079`,
        sameOrigin: false,
        href: `https://wa.me/5516982000079?lang=pt-BR&text=${wppText}`,
      },
      {
        text: `contato@roberty.app`,
        sameOrigin: false,
        href: `mailto:contato@roberty.app`,
      },
    ],
  },
]
