// extracted by mini-css-extract-plugin
export var active = "header-module--active--byAzz";
export var backdrop = "header-module--backdrop--YfuTE";
export var hasSales = "header-module--hasSales--ZcA0H";
export var header = "header-module--header--4dQNe";
export var headerSticky = "header-module--headerSticky--yoqec";
export var logo = "header-module--logo--WAoX3";
export var mainMenu = "header-module--mainMenu--bzqEw";
export var menuItem = "header-module--menuItem--902FT";
export var menuMobile = "header-module--menuMobile--dQdaJ";
export var mobileNav = "header-module--mobileNav--zgoSp";
export var sticky = "header-module--sticky--BjaKd";
export var toggle = "header-module--toggle--hna1r";
export var whiteMenu = "header-module--whiteMenu--2CiFm";